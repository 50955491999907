import styled from 'styled-components';
import { color, space, typography } from 'styled-system';

const Typography = styled.p`
    ${space}
    ${typography}
    ${color}
`;

Typography.defaultProps = {
    fontSize: 'sm',
    fontWeight: 'regular',
};

export default Typography;
